import {
    Form,
    FormProps,
    FormValues,
} from "@baloise-cfa/form-renderer-frontend";
import { format } from "date-fns";
import { graphql, navigate } from "gatsby";
import { TFunction } from "i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";

import {
    QuoteFormKey,
    quoteFormState,
    QuoteFormState,
    QuoteLayout,
    TabsLayout,
    validateQuoteBusinessRules,
    vehicleFormConfig,
    VehicleHeading,
} from "@modules/quote";
import { CompletedCard, Navigation } from "@modules/shared/components";
import { CarType } from "@modules/shared/enums";
import { TitleSubTitleDescription } from "@modules/shared/fields";
import { getNavigationPages, toTitle } from "@modules/shared/helpers";
import { useWtc } from "@modules/shared/hooks";
import { AppState, appState } from "@modules/shared/state";
import { PageProps, TabsStatus } from "@modules/shared/types";

const getSafetyOptions = (options: string[], t: TFunction) => {
    if (!options?.length) return "";
    return options.reduce((acc: TitleSubTitleDescription[], option) => {
        switch (option) {
            case "adaptive-cruise":
                acc.push({
                    subTitle: t(
                        "all.options.safetyoptions.adaptivecruise.subtitle",
                    ),
                    title: t("all.options.safetyoptions.adaptivecruise.title"),
                });
                break;
            case "lane-assist":
                acc.push({
                    subTitle: t(
                        "all.options.safetyoptions.laneassist.subtitle",
                    ),
                    title: t("all.options.safetyoptions.laneassist.title"),
                });
                break;
            case "stopping-system":
                acc.push({
                    subTitle: t(
                        "all.options.safetyoptions.stoppingsystem.subtitle",
                    ),
                    title: t("all.options.safetyoptions.stoppingsystem.title"),
                });
                break;
            case "blind-corner":
                acc.push({
                    subTitle: t(
                        "all.options.safetyoptions.blindcorner.subtitle",
                    ),
                    title: t("all.options.safetyoptions.blindcorner.title"),
                });
                break;
            case "fatigue-recognition":
                acc.push({
                    subTitle: t(
                        "all.options.safetyoptions.fatiguerecognition.subtitle",
                    ),
                    title: t(
                        "all.options.safetyoptions.fatiguerecognition.title",
                    ),
                });
                break;
        }

        return acc;
    }, []);
};

const formatDate = (dateString?: string): string => {
    return dateString ? format(new Date(dateString), "dd/MM/yyyy") : "/";
};

const Vehicle: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, language, ownPageObjects: allPages } = pageContext;
    const { t } = useTranslation();
    const { nextPage, prevPage } = getNavigationPages(allPages, page);
    const [formState, setFormState] = useRecoilState(quoteFormState);
    const appData = useRecoilValue<AppState>(appState);
    const { getWtcPage, businessRules } = useWtc(allPages);
    const [formProps, setFormProps] = useState<
        Pick<FormProps<FormValues>, "dirty" | "isValid">
    >({
        isValid: false,
        dirty: false,
    });

    useEffect(() => {
        if (!appData?.InsuranceChoice?.insurance?.type) {
            // Navigate to the product page when there is no insurance choice selected
            // A quote flow can only started when there is a simulation available
            navigate("/");
        }
    }, [appData]);

    const carModel = useMemo(() => {
        return `Ford ${toTitle(
            appData?.CarDescription?.carDescription?.model,
        )}`;
    }, [appData]);

    const initialFormValues: QuoteFormState = useMemo(() => {
        const isNotNewCar = appData.BeforeWeStart?.cartype !== CarType.New;
        const power = `${String(
            appData?.CarDescription?.carDescription?.power,
        )} ${t("all.power.abbr")} (${Math.round(
            Number(appData?.CarDescription?.carDescription?.power) * 1.362,
        )} ${t("all.power.horsepower")})`;

        return {
            ...formState,
            vehicle: {
                ...formState?.vehicle,
                driverDataFromSimulation: [
                    {
                        title: "all.carDescription.model",
                        description: carModel,
                    },
                    {
                        title: "quote.vehicle.kmPerYear",
                        description: t(
                            `all.options.mileage.${appData?.CarUsage?.mileage}`,
                        ) as string,
                    },
                    {
                        title: "all.carDescription.fuelType",
                        description: t(
                            `all.fueltype.${appData?.CarDescription?.carDescription?.fuel.toLowerCase()}`,
                        ) as string,
                    },
                    {
                        title: "all.carDescription.power",
                        description: power,
                    },
                    {
                        title: isNotNewCar
                            ? "quote.vehicle.invoicevalue"
                            : "quote.vehicle.catalogvalue",
                        description: isNotNewCar
                            ? appData?.CarDescription?.invoicevalue
                            : appData?.CarDescription?.catalogvalue,
                    },
                    {
                        title: "quote.vehicle.registrationdate",
                        description: isNotNewCar
                            ? formatDate(appData?.CarUsage?.registrationdate)
                            : "",
                    },
                    {
                        title: "quote.vehicle.safetyOptions",
                        description: getSafetyOptions(
                            appData?.CarDescription?.safetyOptions ?? [],
                            t,
                        ) as string,
                    },
                ],
                vehicleInfo: {
                    ...formState?.vehicle?.vehicleInfo,
                    seats: formState?.vehicle?.vehicleInfo?.seats
                        ? formState?.vehicle?.vehicleInfo?.seats
                        : appData.BeforeWeStart?.lightLoadVehicle
                        ? 2
                        : 4,
                },
            },
            simulatorContext: {
                ...formState?.simulatorContext,
                carType:
                    formState?.simulatorContext?.carType ??
                    appData.BeforeWeStart?.cartype,
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appData.CarDescription, appData.CarUsage]);

    const handleSubmit = (values: FormValues): void => {
        setFormState(values);

        const wtcPage = getWtcPage(
            validateQuoteBusinessRules(businessRules, formState, appData)
                .action,
        );

        if (wtcPage) {
            navigate(wtcPage.paths[language]);
            return;
        }
        if (nextPage) {
            navigate(nextPage.paths[language]);
        }
    };

    const handleOnChange = (
        values: FormValues,
        props: Pick<FormProps<FormValues>, "dirty" | "isValid">,
    ): void => {
        setFormProps(props);
        setFormState(values);
    };

    const renderHeaderComponent = useCallback(
        (tabsStatus: TabsStatus) => {
            const showCompleteCard = formProps.dirty
                ? formProps.isValid
                : tabsStatus && tabsStatus[page.name]?.isValid;

            if (showCompleteCard) {
                return (
                    <CompletedCard
                        nextPage={nextPage}
                        currentPageText={carModel}
                        language={language}
                    />
                );
            }
        },
        [carModel, language, nextPage, page, formProps],
    );

    return (
        <QuoteLayout
            title={t("quote.vehicle.title")}
            page={page}
            allPages={allPages}
            language={language}
        >
            <TabsLayout
                title={t("quote.vehicle.title")}
                description={t("quote.vehicle.description")}
                language={language}
                allPages={allPages}
                activeTabName={page.name}
            >
                {({ tabsStatus }) => {
                    return (
                        <>
                            <VehicleHeading
                                subTitle={t("quote.navigation.vehicle")}
                                title={carModel}
                            />
                            <Form
                                scrollToFieldError
                                errorMessageCardTitle={
                                    t("all.errormessage.title") as string
                                }
                                errorMessageCardSubTitle={
                                    t("all.errormessage.text") as string
                                }
                                formContext={{
                                    t,
                                    nameSpace: QuoteFormKey.Vehicle,
                                    fieldWrapper: {
                                        optionalLabel: "all.optional",
                                    },
                                }}
                                initialValues={initialFormValues}
                                onSubmit={handleSubmit}
                                onChange={handleOnChange}
                                fields={vehicleFormConfig.fields}
                                headerComponent={() =>
                                    renderHeaderComponent(tabsStatus)
                                }
                                enableReinitialize
                            >
                                <Navigation
                                    t={t}
                                    language={language}
                                    prevPage={prevPage}
                                    nextPage={nextPage}
                                    nextPageBtnText="all.next"
                                    nextColor="info"
                                />
                            </Form>
                        </>
                    );
                }}
            </TabsLayout>
        </QuoteLayout>
    );
};

export default Vehicle;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
